export default {
	"SUN2000-2KTL-L": { maxACDCPower: 2000, maxMPPTPower: 3000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 2000 },
	"SUN2000-3KTL-L": { maxACDCPower: 3000, maxMPPTPower: 4500, maxBatteryChargePower: 5000, maxBatteryDischargePower: 3300 },
	"SUN2000-3.68KTL-L": { maxACDCPower: 3680, maxMPPTPower: 5520, maxBatteryChargePower: 5000, maxBatteryDischargePower: 3680 },
	"SUN2000-4KTL-L": { maxACDCPower: 4000, maxMPPTPower: 6000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 4400 },
	"SUN2000-4.6KTL-L": { maxACDCPower: 4600, maxMPPTPower: 6900, maxBatteryChargePower: 5000, maxBatteryDischargePower: 4600 },
	"SUN2000-5KTL-L": { maxACDCPower: 5000, maxMPPTPower: 7500, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"SUN2000-6KTL-L": { maxACDCPower: 6000, maxMPPTPower: 9000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"SUN2000-3KTL-M0": { maxACDCPower: 3000, maxMPPTPower: 6000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 2400 },
	"SUN2000-4KTL-M0": { maxACDCPower: 4000, maxMPPTPower: 8000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 4400 },
	"SUN2000-5KTL-M0": { maxACDCPower: 5000, maxMPPTPower: 10000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 5500 },
	"SUN2000-6KTL-M0": { maxACDCPower: 6000, maxMPPTPower: 12000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 6600 },
	"SUN2000-8KTL-M0": { maxACDCPower: 8000, maxMPPTPower: 14880, maxBatteryChargePower: 10000, maxBatteryDischargePower: 8800 },
	"SUN2000-10KTL-M0": { maxACDCPower: 10000, maxMPPTPower: 14880, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"SUN2000-12KTL-M0": { maxACDCPower: 12000, maxMPPTPower: 24000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"SUN2000-15KTL-M0": { maxACDCPower: 15000, maxMPPTPower: 29760, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"SUN2000-17KTL-M0": { maxACDCPower: 17000, maxMPPTPower: 29760, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"SUN2000-20KTL-M0": { maxACDCPower: 20000, maxMPPTPower: 29760, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"SUN2000-3KTL-M1": { maxACDCPower: 3000, maxMPPTPower: 6000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 3300 },
	"SUN2000-4KTL-M1": { maxACDCPower: 3000, maxMPPTPower: 6000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 4400 },
	"SUN2000-5KTL-M1": { maxACDCPower: 5000, maxMPPTPower: 7500, maxBatteryChargePower: 10000, maxBatteryDischargePower: 5500 },
	"SUN2000-6KTL-M1": { maxACDCPower: 6000, maxMPPTPower: 9000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 6600 },
	"SUN2000-8KTL-M1": { maxACDCPower: 8000, maxMPPTPower: 12000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 8800 },
	"SUN2000-10KTL-M1": { maxACDCPower: 10000, maxMPPTPower: 15000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"SUN2000-12KTL-M1": { maxACDCPower: 12000, maxMPPTPower: 18000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"SmartLogger3000": { maxACDCPower: 5000, maxMPPTPower: 5000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
};
	

