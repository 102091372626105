import validator from "@rjsf/validator-ajv8";
import PVPanelsStep from "../components/pv-panels-step.component";

export default {
  title: "Solar Panels",
  description: "Define and configure the solar panels.",
  component: PVPanelsStep,
  schemaFunction: ({ schemas }) => {
    return {
      pvArrays: {
        ...schemas,
        $id: "pvArrays",
        properties: {
          pvArrays: schemas.definitions.Converter.properties.pvArrays,
        },
        required: [],
      },
      pvArraysRetrofit: {
        ...schemas,
        $id: "pvArraysRetrofit",
        properties: {
          pvArraysRetrofit: schemas.properties.pvArraysRetrofit,
        },
        required: [],
      },
    };
  },
  uiSchema: {
    pvArrays: {
      "ui:title": false,
      "ui:description": false,
      pvArrays: {
        "ui:options": {
          title: "PV Panels",
        },
        items: {
          "ui:options": {
            title: false,
          },
          solarPanelTilt: {
            "ui:description": "Horizontal=0, Vertical=90, any corresponding value between 0 and 90",
          },
          solarPanelOrientation: {
            "ui:description": "E=-90, SE=-45, S=0, SW=45, W=90, or any corresponding value between -180 and 180",
          },
        },
      },
    },
    pvArraysRetrofit: {
      "ui:title": false,
      "ui:description": false,
      pvArraysRetrofit: {
        "ui:options": {
          title: "Retrofit Panels",
        },
        items: {
          "ui:options": {
            title: false,
          },
          solarPanelTilt: {
            "ui:description": "Horizontal=0, Vertical=90, any corresponding value between 0 and 90",
          },
          solarPanelOrientation: {
            "ui:description": "E=-90, SE=-45, S=0, SW=45, W=90, or any corresponding value between -180 and 180",
          },
        },
      },
    },
  },
  validateFunction: (schema, formData) => {
    if (!formData) return true;

    let isValid = true;
    if (formData.pvArrays) {
      isValid = validator.isValid(
        schema.pvArrays,
        { pvArrays: formData.pvArrays },
        schema.pvArrays
      );
    }

    if (formData.pvArraysRetrofit) {
      isValid = validator.isValid(
        schema.pvArraysRetrofit,
        { pvArraysRetrofit: formData.pvArraysRetrofit },
        schema.pvArraysRetrofit
      );
    }
    return isValid;
  },
  validateFormData: (schema, formData) => {
    if (!formData) return true;

    const validationData = {};

    if (formData.pvArrays) {
      validationData.pvArrays = validator.validateFormData(schema.pvArrays, {
        pvArrays: formData.pvArrays,
      });
    }

    if (formData.pvArraysRetrofit) {
      validationData.pvArraysRetrofit = validator.validateFormData(
        schema.pvArraysRetrofit,
        { pvArraysRetrofit: formData.pvArraysRetrofit }
      );
    }

    return validationData;
  },
};
