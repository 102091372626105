import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import 'Amplify';
import '@lifepowr/components/src/App.scss';
import store from "@lifepowr/components/src/store";
import LandingPage from "components/landing-page";
import withAuth from '@lifepowr/components/src/utils/auth/checkLogin';
import { ThemeProvider, createTheme, /* makeStyles */ } from '@mui/material/styles';
import getClientInfo from "@lifepowr/components/src/clients";

const theme = createTheme(
  {
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
    },
  },
);

// Function to set the favicon and other icons
function setIcon(rel, type, sizes, href) {
  let link = document.querySelector(`link[rel='${rel}'][sizes='${sizes}']`) || document.createElement('link');
  link.rel = rel;
  if (type) link.type = type;
  if (sizes) link.sizes = sizes;
  link.href = href;
  document.getElementsByTagName('head')[0].appendChild(link);
}

const clientInfo = getClientInfo();

// Set the document title and icons if clientInfo is available
if (clientInfo) {
  if (clientInfo.title) {
    window.document.title = clientInfo.title;
  }
  if (clientInfo.favicon) {
    setIcon('icon', 'image/x-icon', '', clientInfo.favicon);
  }
  if (clientInfo.appleTouchIcon) {
    setIcon('apple-touch-icon', 'image/png', '180x180', clientInfo.appleTouchIcon);
  }
  if (clientInfo.favicon32) {
    setIcon('icon', 'image/png', '32x32', clientInfo.favicon32);
  }
  if (clientInfo.favicon16) {
    setIcon('icon', 'image/png', '16x16', clientInfo.favicon16);
  }
}

function App() {
  return (
  	<ThemeProvider theme={theme}>
	  	<Provider store={store}>
				<Router basename={process.env.REACT_APP_BASENAME || ''}>
					<LandingPage />
				</Router>
			</Provider>
		</ThemeProvider>
  );
}

export default withAuth(App, 'partner');
